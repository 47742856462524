<template>
  <div class="container-xl">
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">
          <div class="flex flex-row mb-2 pr-2 float-right">
            <el-input clearable @clear="searchHandlerBanner" v-model="search" style="width: 200px" size="small" type="text" placeholder="Input search keyword" class="mr-2"/>
            <el-button type="primary" @click="searchHandlerBanner" size="small"><font-awesome-icon icon="search"/> Search</el-button>
          </div>
        </div>
        <el-button @click="handlerBtnContent" type="primary" size="mini"><font-awesome-icon icon="plus"/> Add Banner</el-button>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%" @click="toggleSort('title')" style="cursor: pointer"> Title
                 <span v-if="sortKey === 'title'">
                  {{ sortOrder === 1 ? '🔼' : '🔽' }}
                </span>
              </th>
              <th width="20%">Image</th>
              <!-- <th>Created At</th> -->
              <th>Updated At</th>
              <th>Target Page </th>
              <th width="20%">Status</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in sortedList" :key="item.id">
              <td> {{item.title}} </td>
              <td>
                <img :src="item.image_url" alt="" class="card-img-top" style="width: 70px; height: 60px;"> 
              </td>
              <!-- <td> {{item.created_at_str}} </td> -->
              <td> {{item.updated_at_str}} </td>
              <td class="text-nowrap text-muted child2-sticky">
                <template v-if="item.target_page.includes('login')">Login</template>
                <template v-else-if="item.target_page.includes('home')">Home</template>
             </td>
              <td class="text-nowrap text-muted child2-sticky"><span class="tag" :class="item.banner_status_color">{{ item.banner_status_str }}</span></td>
              <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                    <a class="dropdown-item btn-sm cursor-pointer" @click="handlerBtnEdit(item)">
                      <font-awesome-icon class="text-primary" :icon="['fas', 'pen']"/>&nbsp; Edit Popup Banner
                    </a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="handlerBtnStatus(item)">
                      <font-awesome-icon class="text-primary" :icon="['fas', 'pen']"/>&nbsp; Status Banner
                    </a>
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_row == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_row }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="size"
            :limit="5"
            :total-rows="total_row"
            v-model="page"
          />
        </div>
      </div>
      <!-- ADD -->
      <b-modal size="lg" v-model="isShownModalBanner" :title="modalTitle" hide-footer @hide="onHide">
        <b-form @submit.prevent="onSubmit" @reset="onHide" v-loading="loading.detail">
          <b-form-group label="Title">
            <b-input
              v-model="form.title"
              placeholder="Input content title"
              type="text"
            />
            <span class="text-danger" v-if="!form.title">
              Title is required
            </span>
          </b-form-group>
          <b-form-group label="Upload Image">
            <b-form-file
              v-model="selected_file.file_image"
              :state="Boolean(selected_file.file_image)"
              class="mt-2 border-1 p-2"
              accept=".png, .jpg, .jpeg"
              @input="onChangeFile($event, 'file_image')"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <!-- <img ref="current_file_image" :src="form.current_file_image"/> -->
            <span class="text-danger" v-if="!selected_file.file_image" >Image is required</span>
          </b-form-group>
          <b-form-group label="Target Page">
            <el-select
              @change="handleCompanyType" 
              class="mb-2"
              v-model="form.target_page"
              placeholder="Select Target Page"
              style="width: 100%"
            >
              <el-option
                v-for="item in targetOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="text-danger" v-if="!form.target_page || form.target_page.length === 0">
              Target Page is required
            </span>
          </b-form-group>
          <b-form-group label="Page To Open">
            <template>
              <el-select
                class="w-full"
                @change="pageDestinationChangeHandler"
                v-model="form.page_destination"
                placeholder="Select page URL">
                <el-option v-for="(item, index) in destination_options" :value="item.value" :key="index" :label="item.label"/>
              </el-select>
            </template>
          </b-form-group> 
          <b-form-group label="Target URL" v-if="['/voucher', '/merchant', '/promo'].includes(form.page_destination)">
            <el-select
              class="w-full"
              filterable
              remote
              @change="targetChangeHandler"
              :loading="targetLoading"
              :remote-method="targetFilterHandler"
              v-model="form.target"
              placeholder="Select target">
              <el-option v-for="(item, index) in target_list" :value="item.value" :key="index" :label="item.label"/>
            </el-select>
          </b-form-group>
          <b-form-group label="Target URL"  v-if="form.page_destination === 'custom'">
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  {{ deepLinkBaseURL }}/
                </b-input-group-text>
              </template>
              <b-input
                v-model="form.target"
                placeholder="Input custom target link. eg: /merchant"
                type="text"/>
            </b-input-group>
          </b-form-group>
          <b-form-group label="UTM Source">
            <b-input v-model="form.utm_source" placeholder="Input UTM Source" type="text"/>
          </b-form-group>
          <b-form-group label="UTM Medium">
            <b-input v-model="form.utm_medium" placeholder="Input UTM Medium" type="text"/>
          </b-form-group>
          <b-form-group label="UTM Campaign">
            <b-input v-model="form.utm_campaign" placeholder="UTM Campaign" type="text"/>
          </b-form-group>
          <b-form-group label="Description">
            <b-textarea
              v-model="v$.form.description.$model"
              placeholder="Input Banner description"/>
            <b-form-invalid-feedback :state="!v$.form.description.$invalid" v-if="v$.form.description.$errors[0]">
              <span> {{ v$.form.description.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Recipient Segment">
            <el-select
              class="w-full"
              v-model="form.user_type"
              @change="handlerChangeSegment"
              placeholder="Select recipient segmentation">
              <el-option v-for="(item, index) in user_typeOptions" :value="item.value" :key="index" :label="item.label"/>
            </el-select>
          </b-form-group>
          <b-form-group label="Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.company_ids" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option
                v-for="item in companies.rows"
                :key="item.company_id"
                :label="item.company_name"
                :value="item.company_id">
              </el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Choose Users" v-if="form.user_type === 'private'">
            <el-button @click="handlerChooseRecipient" size="small">Select Users</el-button>
          </b-form-group>
          <p v-if="form.user_type === 'private'">Total Users: {{ selectedRecipients.length }}</p>
          <div class="d-flex flex-row float-right">
            <el-button @click="onSubmit"  :disabled="!canSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
      <!-- EDIT -->
      <b-modal size="lg" v-model="isShownModalEditBanner" :title="'Update #' + selected_item._id" hide-footer @hide="onHide" @shown="parseUTMParams">
        <b-form @submit.prevent="doUpdateStatus" @reset="onHide" v-loading="loading.detail">
          <b-form-group label="Title">
            <b-input
              v-model="selected_item.title"
              placeholder="Input content title"
              type="text"/>
          </b-form-group>
          <b-form-group label="Upload Image">
            <b-form-file
              v-model="selected_item.file_image"
              :state="Boolean(selected_item.file_image)"
              class="mt-2 border-1 p-2"
              accept=".png, .jpg, .jpeg"
              @input="onChangeFileEditBanner($event, 'file_image')"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-form-group>
          <b-form-group label="Current Image" v-if="!selected_item.file_image">
            <img :src="selected_item.image_url" width="210"  hight="200" alt="Tabler" >
          </b-form-group>
          <b-form-group label="Current UTM">
            <b-input v-model="selected_item.utm" disabled  type="text"/>
          </b-form-group>
          <b-form-group label="Page To Open">
            <template>
              <el-select
                class="w-full"
                @change="pageDestinationChangeHandler"
                v-model="form.page_destination"
                placeholder="Select page URL">
                <el-option v-for="(item, index) in destination_options" :value="item.value" :key="index" :label="item.label"/>
              </el-select>
            </template>
          </b-form-group> 
          <b-form-group label="Target URL" v-if="['/voucher', '/merchant', '/promo'].includes(form.page_destination)">
            <el-select
              class="w-full"
              filterable
              remote
              @change="targetChangeHandler"
              :loading="targetLoading"
              :remote-method="targetFilterHandler"
              v-model="form.target"
              placeholder="Select target">
              <el-option v-for="(item, index) in target_list" :value="item.value" :key="index" :label="item.label"/>
            </el-select>
          </b-form-group>
          <b-form-group label="Target URL"  v-if="form.page_destination === 'custom'">
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  {{ deepLinkBaseURL }}/
                </b-input-group-text>
              </template>
              <b-input
                v-model="form.target"
                placeholder="Input custom target link. eg: /merchant"
                type="text"/>
            </b-input-group>
          </b-form-group>
          <b-form-group label="UTM Source">
            <b-input v-model="form.utm_source" placeholder="Input UTM Source" type="text"/>
          </b-form-group>
          <b-form-group label="UTM Medium">
            <b-input v-model="form.utm_medium" placeholder="Input UTM Medium" type="text"/>
          </b-form-group>
          <b-form-group label="UTM Campaign">
            <b-input v-model="form.utm_campaign" placeholder="UTM Campaign" type="text"/>
          </b-form-group>
          <b-form-group label="Target Page">
            <el-select class="mb-2"  v-model="selected_item.target_page_text" placeholder="Select Target Page" style="width: 100%">
              <el-option
                v-for="item in targetOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Description">
            <b-textarea
              v-model="selected_item.description"
              placeholder="Input Banner description"/>
          </b-form-group>
          <b-form-group label="Recipient Segment">
            <el-select
              class="w-full"
              v-model="selected_item.user_type"
              @change="handlerChangeSegment"
              placeholder="Select recipient segmentation">
              <el-option v-for="(item, index) in user_typeOptions" :value="item.value" :key="index" :label="item.label"/>
            </el-select>
          </b-form-group>
          <b-form-group label="Company" v-if="selected_item.user_type === 'private'">
            <el-select multiple class="mb-2" @change="handleCompanyChange" :loading="loading.company" v-model="selected_item.company_ids" style="width: 100%">
              <el-option
                v-for="item in companies.rows"
                :key="item.company_id"
                :label="item.company_name"
                :value="item.company_id">
              </el-option>
            </el-select>
          </b-form-group>
           <b-form-group label="Choose Users" v-if="selected_item.user_type === 'private'">
            <el-button @click="handlerChooseRecipient" size="small">Select recipient</el-button>
          </b-form-group>
         <!-- owned by {{ this.selectedRecipients}}<br> -->
          <p v-if="selected_item.user_type === 'private'"> Total users {{ selectedRecipients.length }}</p>
          <div class="d-flex flex-row float-right">
            <el-button @click="doUpdateStatus" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
      <!-- MODAL USERS -->
      <b-modal size="xl" v-model="showModalRecipientTable" title="Choose users" hide-footer @hide="onHideApp" body-class="p-0">
        <div class="card">
          <div class="card-header justify-content-between">
            <div class="card-title">
              App User
            </div>
            <div class="flex flex-row mb-2 pr-2 float-right">
              <b-input style="width: 200px" size="sm" type="text" v-model="user_filter.search_keyword" placeholder="Input search keyword" class="mr-2"/>
              <el-select size="small" v-model="user_filter.search_by" class="mr-2" style="width: 150px">
                <el-option v-for="(item, index) in user_filter.search_options" :key="index" :value="item.value" :label="item.text"/>
              </el-select>
              <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
            </div>
          </div>
          <div class="table-responsive">
            <el-table :data="list_app"
              ref="list_table"
              v-loading="loading.table"
              @selection-change="handlerSelectionChange"
              class="table card-table text-nowrap table-vcenter"
              style="width: 100%">
              <el-table-column type="selection" width="65"/>
              <el-table-column width="200" prop="full_name" label="Name" :sortable="true"/>
              <el-table-column width="200" prop="company_name" label="Company"></el-table-column>
              <el-table-column min-width="250" prop="email" label="Email"></el-table-column>
              <el-table-column width="150" prop="phone_number" label="Phone Number"></el-table-column>
              <el-table-column width="150" prop="created_at_str" label="Join Date"></el-table-column>
              <el-table-column width="150" prop="login_at_str" label="Last Login Date"></el-table-column>
              <el-table-column width="120" prop="private_status" label="Account Type">
                <template slot-scope="item">
                  <span class="tag" :class="item.row.private_status_color">{{ item.row.private_status }}</span>
                </template>
              </el-table-column>
              <el-table-column width="150" prop="private_status" label="KYC Status">
                <template slot-scope="item">
                  <span class="tag" :class="item.row.kyc_status_color">{{ item.row.kyc_status_str }}</span>
                </template>
              </el-table-column>
              <el-table-column width="150" prop="status" label="Status">
                <template slot-scope="item">
                  <span class="tag" :class="item.row.status_color">{{ item.row.status_str }}</span>
                </template>
              </el-table-column>
              <template slot="empty">
                <el-empty description="No data found" :image="emptyStateImage"></el-empty>
              </template>
            </el-table>
          </div>
          <div class="d-flex justify-content-between p-2">
            <small>Showing {{ showingFromApp }} to {{ showingUntilApp }} of {{ total_rows }} entries</small>
            <div class="inline-table">
              <b-pagination
                class="table-cell"
                @change="pageChangeHandlerApp"
                :per-page="per_page"
                :limit="5"
                :total-rows="total_rows"
                v-model="page"
              />
            </div>
          </div>
        </div>
        <br>
        <div class="d-flex justify-content-between p-2">
        <div class="inline-table">
        </div>
        </div>
        <br>
        <div class="float-right p-2">
          <el-button @click="onSubmitApp" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit App</el-button>
          <el-button @click="onHideApp" size="small" class="mr-2 ml-2">Cancel</el-button>
          <br>
          {{ temp_selected }}
        </div>
      </b-modal>
      <!-- EDIT STATUS -->
      <b-modal size="lg" v-model="isShownModalStatusEdit" :title="'Update Status #' + selected_item._id" hide-footer @hide="onHide">
        <b-form @submit.prevent="doUpdateStatusBanner" @reset="onHide" v-loading="loading.detail">
          <b-form-group label="Title">
            <b-input
               v-model="selected_item.title"
               disabled
               placeholder="Input content title"
               type="text"/>
          </b-form-group>
          <b-form-group label="Status">
            <el-select class="mb-2" v-model="selected_item.status" placeholder="Select Status Banner" style="width: 100%">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="doUpdateStatusBanner" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHideStatus" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
// import { UPDATE_SECTIONCONTENT, DELETE_SECTIONCONTENT } from '@/store/modules/app_content';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
// import ModalSectionContent from './ModalSectionContent.vue';
import {
GET_POPUP_BANNER, CREATE_POPUPBANNER, UPDATE_POPUP_BANNER, UPDATE_STATUS_POPUP_BANNER,GET_APPUSERS } from '@/store/modules/app_user';
// import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { GET_COMPANIES } from '@/store/modules/companies';
import base64File from '@/library/base64File';
import imageCompress from '@/library/vue-image-compression';
import {
  required, maxLength, requiredIf,
} from '@vuelidate/validators';
import { isEmpty, uniqBy, clone } from 'lodash';
import url from 'url';
import { GET_MERCHANTS } from '@/store/modules/merchants';
import { GET_VOUCHERS } from '@/store/modules/vouchers';
import { GET_PROMOS } from '@/store/modules/promo';

const deepLinkBaseURL = 'app://app.fortuna.venteny';

export default {
  metaInfo: {
    title: 'Pop Up Banner',
  },
  data() {
    return {
      deepLinkBaseURL,
      selectedRecipients: [],
      search_keyword: 'full_name',
      search: '',
      temp_checked: {},
      checked: {},
      user_filter: {
        search_by: 'full_name',
        search_keyword: '',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      // temp_selected: null,
      temp_selected: [],
      companies: {
        rows: [],
        count: 0,
      },
      app_users: {
        rows: [],
        count: 0,
      },
      sortKey: 'title', 
      sortOrder: 1 ,
      isSorted: "",
      selected_item: {
        title: '',
        image_url: '',
        status: '',
        description: '' ,
        target_url: '' ,
        target_page: [] ,
        page_destination: '',
        target: '',
        // user_ids: [] ,
        user_type: 'all',
        user_ids: '' ,
        company_ids: '' ,
        file_image: null,
        file_image_base64: '',
        target_page_str: []
      },
      emptyStateImage,
      isShownModalContent: false,
      isShownModalEditBanner: false,
      showModalRecipientTable: false,
      isShownModalStatusEdit: false,
      loading: {
        modal_content: false,
        table: false,
      },
      modalAction: 'add',
      page: 1,
      per_page: 10,
      size: 10,
      list: [],
      list_app: [],
      total_row: 0,
      total_rows: 0,
      total_rows_app: 0,
      selected_content: {},
      isShownModalBanner: false,
      modalTitle: 'Popup Banner',
      form: {
        title: '',
        file_image: null,
        target_url: '',
        target_page: [],
        description: '',
        company_ids: [],
        user_type: '',
        user_ids: [],
        page_destination: '',
        target: '',
        utm: '',
        utm_campaign: '',
        utm_medium: '',
        utm_source: ''
      },
      tempTarget: {},
      target_list: [],
      selected_file: {
        file_image: null,
        file_image_base64: '',
      },
      loading: {
        detail: false,
        submit: false,
        company: false,
        table: false
      },
      targetLoading: false,
      data_banner: [],
      orderByOptions: [],
      targetOptions: [],
      CompanyIdsOptions: [],
      UserOptions: [],
      statusOptions: [],
      type: 'create', 
      showModalRecipient: false,
      orderByOptions: [
        {
          value: 'transaction',
          label: 'Transaction',
        },
      ],
      user_typeOptions: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'private',
          label: 'Private',
        },
        {
          value: 'public',
          label: 'Public',
        },
      ],
      targetOptions: [
        {
          value: 'home',
          label: 'Home',
        },
        {
          value: 'login',
          label: 'Login',
        },
      ],
      statusOptions: [
        {
          value: true,
          label: 'Active',
        },
        {
          value: false,
          label: 'Inactive',
        },
      ],
      destination_options: [
        {
          value: '',
          label: 'Home',
        },
        {
          value: 'custom',
          label: 'Custom',
        },
        {
          value: '/merchant',
          label: 'Merchant Voucher List',
        },
        {
          value: '/voucher',
          label: 'Voucher',
        },
        {
          value: '/history',
          label: 'Transaction History',
        },
        {
          value: '/promo',
          label: 'Promo',
        },
      ],
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
  return {
    form: {
      image_url: { required, url },
      target_url: { required, url },
      description: { required, maxLength: maxLength(255) },
      company_ids: { required },
      user_ids: { required },
      // status: { required },
    },
  };
  },
  mounted() {
    this.getList();
    this.parseUTMParams();
  },
  computed: {
    canSubmit() {
      return this.form.title && this.selected_file.file_image && this.form.target_page;
    },
    selectedRecipients() {
      return this.temp_selected;
    },
    showingFrom() {
      return this.total_row ? ((this.page - 1) * this.size) + 1 : 0;
    },
    showingUntil() {
      if (this.total_row) {
        if (this.list.length < this.size) {
          return this.total_row;
        }
        return this.page * this.size;
      }
      return 0;
    },
    showingFromApp() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntilApp() {
      if (this.total_rows) {
        if (this.list_app.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    sortedList() {
      let sorted = this.list;
      if (this.isSorted) {
        sorted = this.list.slice().sort((a, b) => {
          let result = 0;
          if (a[this.sortKey] < b[this.sortKey]) result = -1;
          if (a[this.sortKey] > b[this.sortKey]) result = 1;
          return result * this.sortOrder;
        });
      }
      return sorted;
    }
  },
  methods: {
    parseUTMParams() {
      const utmUrl = new URL(this.selected_item.utm);
      const pathSegments = utmUrl.pathname.split('/'); 
      const destination = pathSegments[pathSegments.length - 1];
      const params = new URLSearchParams(utmUrl.search);
      if (destination === 'merchant') {
        this.selected_item.page_destination = '/merchant';
        this.selected_item.target = params.get('merchant_id') || '';
      } else if (destination === 'voucher') {
        this.selected_item.page_destination = '/voucher';
        this.selected_item.target = params.get('voucher_id') || '';
      } else if (destination === 'promo') {
        this.selected_item.page_destination = '/promo';
        this.selected_item.target = params.get('_id') || '';
      } else {
        this.selected_item.page_destination = 'custom';
        this.selected_item.target = ''; 
      }
      this.form.utm_source = params.get('utm_source') || '';
      this.form.utm_medium = params.get('utm_medium') || '';
      this.form.utm_campaign = params.get('utm_campaign') || '';
    },
    handlerChangeSegment(value) {
      if (value === 'private') {
        this.form.company_id = [];
        this.loadCompanies();
      }
    },
    targetChangeHandler(value) {
      this.tempTarget = this.target_list.find((v) => v.value === value);
    },
    async getMerchantList(keyword) {
      this.targetLoading = true;
      await this.$store.dispatch(GET_MERCHANTS, {
        search_by: 'merchant_name',
        search_keyword: keyword,
        status_filter: 'active',
      }).catch(() => {});
      this.target_list = [{
        label: 'No Target',
        value: '',
      }];
      if (!isEmpty(this.tempTarget)) {
        this.target_list.push(this.tempTarget);
      }
      const { rows } = this.$store.getters.merchants;
      this.target_list.push(...rows.map((v) => ({ value: v.merchant_id, label: v.merchant_name })));
      this.target_list = uniqBy(this.target_list, 'value');
      this.targetLoading = false;
    },
    async getVoucherList(keyword = '') {
      this.targetLoading = true;
      await this.$store.dispatch(GET_VOUCHERS, {
        page: 1,
        per_page: 20,
        search_by: 'voucher_name',
        search_keyword: keyword,
        status_filter: 'active',
      }).catch(() => {});
      this.target_list = [{
        label: 'No Target',
        value: '',
      }];
      if (!isEmpty(this.tempTarget)) {
        this.target_list.push(this.tempTarget);
      }
      const { rows } = this.$store.getters.vouchers;
      this.target_list.push(...rows.map((v) => ({ value: v.voucher_id, label: v.voucher_name })));
      this.target_list = uniqBy(this.target_list, 'value');
      this.targetLoading = false;
    },
    async getListPromo() {
      this.targetLoading = true;
      await this.$store.dispatch(GET_PROMOS, {
        search_by: 'promo_status',
        search_keyword: 1,
      }).catch(() => {});
      this.target_list = [{
        label: 'No Target',
        value: '',
      }];
      if (!isEmpty(this.tempTarget)) {
        this.target_list.push(this.tempTarget);
      }
      const { rows } = this.$store.getters.promos;
      this.target_list.push(...rows.map((v) => ({ value: v._id, label: v.promo_name })));
      this.target_list = uniqBy(this.target_list, 'value');
      this.targetLoading = false;
    },
    targetFilterHandler(value) {
      clearTimeout(this.timeoutTargetFilter);
      this.timeoutTargetFilter = setTimeout(() => {
        if (this.form.page_destination === '/merchant') this.getMerchantList(value);
        if (this.form.page_destination === '/voucher') this.getVoucherList(value);
        if (this.form.page_destination === '/promo') this.getListPromo(value);
      }, 500);
    },
    pageDestinationChangeHandler(value) {
      this.tempTarget = {};
      this.form.target = '';
      if (value === '/merchant') {
        this.getMerchantList();
      } else if (value === '/voucher') {
        this.getVoucherList();
      } else if (value === '/promo') {
        this.getListPromo();
      }
    },
    async onChangeFile(event, target) {
      if (this.selected_file[target]) {
        const compressed = await imageCompress(this.selected_file[target]);
        this.selected_file[`${target}_base64`] = await base64File(compressed);
        try {
          // this.$refs[`current_${target}`].src = this.selected_file[`${target}_base64`];
          this.form[`current_${target}`] = this.selected_file[`${target}_base64`];
          this.form[target] = this.form[`current_${target}`];
        } catch (error) {
          console.log(error);
        }
      }
    },
    async onChangeFileEditBanner(event, target) {
      if (this.selected_item[target]) {
        const compressed = await imageCompress(this.selected_item[target]);
        this.selected_item[`${target}_base64`] = await base64File(compressed);
        try {
          // this.$refs[`current_${target}`].src = this.selected_item[`${target}_base64`];
          this.form[`current_${target}`] = this.selected_item[`${target}_base64`];
          this.form[target] = this.form[`current_${target}`];
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getListApp() {
      await this.$store.dispatch(GET_APPUSERS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        // company_id: this.companyId,
        company_id: this.form.company_ids,
        order_by: "full_name:asc"
      }).catch(() => {});
      const { rows, count } = this.$store.getters.app_users;

      this.total_rows = count || 0;
      this.list_app = rows;
      // this.loading.table = false;
    },
    async pageChangeHandlerApp(page) {
      this.checked[`page${this.page}`] = this.temp_checked[`page${this.page}`] || [];
      this.page = page;
      await this.getListApp();
      if (this.checked[`page${this.page}`]) {
        this.checked[`page${this.page}`].forEach((v) => {
          const obj = this.list_app.find((k) => k.owned_by === v.owned_by);
          if (obj) {
            setTimeout(() => {
              this.$refs.list_table.toggleRowSelection(obj);
            }, 100);
          }
        });
      }
    },
    // handlerSelectionChange(val) {
    //   this.temp_selected = val.map(item => item.owned_by);
    // },
    handlerSelectionChange(val) {
      const existingIds = new Set(this.temp_selected.map(item => item.owned_by));
      val.forEach(item => {
        if (!existingIds.has(item.owned_by)) {
          this.temp_selected.push(item.owned_by);
        }
      });
    },
    handleCompanyType(value) {
      if (value === 'home') {
        this.form.company_id = [];
        this.loadCompanies();
        this.getListApp();
      }
    },
    searchHandler() {
      this.page = 1;
      this.search_by = this.user_filter.search_by;
      this.search_keyword = this.user_filter.search_keyword;
      this.getListApp();
    },
    searchHandlerBanner() {
      this.page = 1;
      this.search = this.search;
      this.getList();
    },
    onSubmitApp() {
      this.selectedRecipients = [...this.temp_selected];
      this.showModalRecipientTable = false;
    },
    handlerChooseRecipient() {
      this.showModalRecipientTable = true;
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    async handleCompanyChange() {
      await this.getListApp();
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.companies = this.$store.getters.companies;
    },
    handlerCloseModalContent() {
      this.isShownModalContent = false;
    },
    handlerBtnContent() {
      this.isShownModalBanner = true;
    },
    handlerAfterSubmit() {
      this.isShownModalContent = false;
      this.getList();
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    handlerBtnEdit(item) {
      this.selected_item = item;
      this.isShownModalEditBanner = true;
      this.loadCompanies();
      this.getListPromo()
      this.getVoucherList()
      this.getMerchantList()
    },
     handlerBtnStatus(item) {
      this.selected_item = item;
      this.isShownModalStatusEdit = true;
     },
    async getList() {
      this.loading.table = true;
      try {
        await this.$store.dispatch(GET_POPUP_BANNER, {
          page: this.page,
          size: this.size,
          search: this.search
        });
        const { rows, count } = this.$store.getters.banners;
        this.list = rows;
        this.total_row = count;
        this.form.current_file_image = item.file_image;
      } catch (err) {
      } finally {
        this.loading.table = false; 
      }
    },
    async onSubmit() {
     const obj = clone(this.form);
      delete obj.file;
      const deeplink_query = {};
      if (obj.page_destination === '/voucher') {
        deeplink_query.voucher_id = obj.target;
      } else if (obj.page_destination === '/merchant') {
        deeplink_query.merchant_id = obj.target;
      } else if (obj.page_destination === '/promo') {
        deeplink_query._id = obj.target; 
      } else if (obj.page_destination === 'custom') {
        obj.page_destination = `/${obj.target}`;
      }
      obj.deeplink = `${deepLinkBaseURL}${url.format({ pathname: obj.page_destination, query: deeplink_query })}`;
      const utm_query = {
          ...deeplink_query,
          utm_source: this.form.utm_source,
          utm_medium: this.form.utm_medium,
          utm_campaign: this.form.utm_campaign
      };
      obj.utm = `${deepLinkBaseURL}${url.format({
          pathname: obj.page_destination,
          query: utm_query
      })}`;
      delete obj.page_destination;
      delete obj.target;
      try {
        const requestBody = {
          title: this.form.title, 
          file_image: this.form.file_image,
          target_page: [this.form.target_page],
          target_url: obj.deeplink, 
          user_type: this.form.user_type,
          description: this.form.description,
          company_ids: this.form.company_ids,
          user_ids: this.selectedRecipients,
          utm:  obj.utm,
        };
        const response = await this.$store.dispatch(CREATE_POPUPBANNER, { data: requestBody });
        this.form.title = '';
        this.form.file_image = '';
        this.form.target_url = '';
        this.form.target_page = '';
        this.form.description = '',
        this.form.user_type = '',
        this.form.company_ids = '',
        this.form.user_ids = '',
        this.selectedRecipients = '',
        this.form.utm_campaign = '',
        this.form.utm_source = '',
        this.form.utm_medium = '',
        this.form.utm = ''
        this.data_banner = response;
        this.$message({
            title: 'Success',
            type: 'success',
            message: 'Popup Banner successfully created',
        });
        this.onHide();
        this.getList();
      } catch (err) {
        popupErrorMessages(err.response.data);
      }
    },
    async doUpdateStatus() {
      this.$confirm(
        'You will update the popup banner. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const obj = clone(this.form);
                delete obj.file;
                const deeplink_query = {};
                if (obj.page_destination === '/voucher') {
                  deeplink_query.voucher_id = obj.target;
                } else if (obj.page_destination === '/merchant') {
                  deeplink_query.merchant_id = obj.target;
                } else if (obj.page_destination === '/promo') {
                  deeplink_query._id = obj.target; 
                } else if (obj.page_destination === 'custom') {
                  obj.page_destination = `/${obj.target}`;
                }
                obj.deeplink = `${deepLinkBaseURL}${url.format({ pathname: obj.page_destination, query: deeplink_query })}`;
                const utm_query = {
                    ...deeplink_query,
                    utm_source: this.form.utm_source,
                    utm_medium: this.form.utm_medium,
                    utm_campaign: this.form.utm_campaign
                };
                obj.utm = `${deepLinkBaseURL}${url.format({
                    pathname: obj.page_destination,
                    query: utm_query
                })}`;
                delete obj.page_destination;
                delete obj.target;
              const requestBody = {
                title: this.selected_item.title, 
                file_image: this.form.file_image ? this.form.file_image : this.selected_item.image_url,
                target_page: [this.selected_item.target_page_text],
                target_url: obj.deeplink,
                description: this.selected_item.description,
                user_type: this.selected_item.user_type,
                company_ids: this.selected_item.company_ids,
                user_ids: this.selectedRecipients ? this.selectedRecipients : [],
                utm : obj.utm
              };
              const _id = this.selected_item._id;
              await this.$store.dispatch(UPDATE_POPUP_BANNER, { _id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Popup Banner updated',
                  });
                  this.getList();
                }).catch((err) => {
                  popupErrorMessages(err.response.data);
                });
                this.onHide();
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async doUpdateStatusBanner() {
      this.$confirm(
        'You will update status of popup banner. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const requestBody = {
                status: this.selected_item.status,
              };
              const _id = this.selected_item._id;
              await this.$store.dispatch(UPDATE_STATUS_POPUP_BANNER, { _id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Status Banner updated',
                  });
                  this.getList();
                  this.isShownModalStatusEdit =  false
                }).catch((err) => {
                  popupErrorMessages(err.response.data);
                });
                this.onHide();
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    onHide() {
      this.isShownModalBanner = false;
      this.isShownModalEditBanner = false;
      this.showModalRecipientTable = false
    },
    onHideApp() {
      this.showModalRecipientTable = false
    },
    onHideStatus() {
      this.isShownModalStatusEdit = false
    },
    toggleSort(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1; 
      } else {
        this.sortKey = key;    
        this.sortOrder = 1;   
      }
      this.isSorted = true;    
    },
    resetSort() {
      this.isSorted = false;  
      this.sortKey = null;
      this.sortOrder = 1;
    }
  },
};
</script>